<script lang="ts">import BsButton from "./BsButton.svelte";
import ButtonGroup from "../ButtonGroup.svelte";
export let id = lib.xf.random();
export let label = "Label not set";
export let type = "text";
export let iType = type;
export let value = undefined;
export let disabled = false;
export let input = undefined;
export let onChange = undefined;
export let list = lib.tacticalUid();
export let onBlur = undefined;
export let helper = undefined;
export let helpers = undefined;
export let help = undefined;
export let required = false;
export let autoSuggest = (str) => [];
export let rightIcon = undefined;
export let name = undefined;
export let clearSelectionsOnChange = true;
export let blockInput = false;
export let getAutoCompletion = lib.xf.noop;
export let genAutoCompletionButtons = () => [];
export let w100 = false;
export let getAutoCompletionSingle = false;
export let datalist_options = undefined;
export let min = undefined;
export let max = undefined;
$: type === "number" && typeof value === "string" && (value = +value);
export let suggestions = [];
let suggestionsPointer = -1;
$: if (Array.isArray(suggestions) && suggestionsPointer > suggestions.length - 1) {
    suggestionsPointer = suggestions.length - 1;
}
export let onDispatch = () => void 0;
const _dispatch = svelte.createEventDispatcher();
const dispatch = ((event, detail) => {
    _dispatch(event, detail);
    // @ts-ignore
    onDispatch(event, detail);
});
const reg = new RegExp(`${String.fromCharCode(92)}$${String.fromCharCode(92)}:[^ ]*$`);
function applySuggestion() {
    const end = input.value.substr(input.selectionStart);
    const str = input.value.substr(0, input.selectionStart);
    const sug = suggestions[suggestionsPointer];
    if (typeof sug === "object" && sug !== null && !!sug.value && !!sug.label) {
        dispatch("apply_suggestion", {
            value: sug.value,
            suggestion: sug,
            position: input.selectionStart
        });
        if (clearSelectionsOnChange)
            suggestions = [];
    }
    else if (typeof sug === "string") {
        const newStr = str.replace(reg, sug);
        value = newStr + end;
        console.log({ newStr, end, reg, sug });
        dispatch("apply_suggestion", {
            value,
            suggestion: sug,
            position: input.selectionStart
        });
        if (clearSelectionsOnChange)
            suggestions = [];
    }
    else {
        console.error(new Error("Invalid suggestion type"));
    }
}
let isFocused = false;
function focus() {
    isFocused = true;
}
function blur() {
    isFocused = false;
}
export let useDefUndef = false;
function defUndef(node) {
    if (useDefUndef) {
        node.onblur = () => {
            if (node.value === "undefined") {
                node.value = "";
            }
        };
    }
}
$: isFocused && suggestionsPointer == -1 && Array.isNotEmpty(suggestions) && (suggestionsPointer = 0);
$: !isFocused && (suggestionsPointer = -1);
svelte.once(() => !!autoSuggest, async () => {
    const _ = await (autoSuggest || lib.xf.noop)("");
    suggestions = Array.isArray(_) ? _ : suggestions;
});
export let suggestions_prepend = `$:`;
let anyobj;
const useTop = (node) => {
    node.style.top = `${input.getBoundingClientRect().bottom}px`;
};
</script>

<div class="d-flex" class:w-100={w100}>
	<div class="form-floating d-flex" class:has-feedback={!!rightIcon} style="flex-grow: 1;">
		<!-- svelte-ignore missing-declaration -->
		<input
			on:focus={focus}
			on:blur={blur}
			on:keydown={(e) => {
				if (e.key === "ArrowUp") {
					suggestionsPointer--;
					e.stopPropagation();
				} else if (e.key === "ArrowDown") {
					suggestionsPointer++;
					e.stopPropagation();
				} else if (e.key === "Enter") {
					applySuggestion();
					e.stopPropagation();
				}
			}}
			on:input={async (e) => {
				if (blockInput) {
					e.target.value = value;
				} else {
					value = e.target.type === "number" ? e.target.valueAsNumber : e.target.value;
				}
				const _ = await (autoSuggest || lib.xf.noop)(
					// @ts-ignore
					e.target.value.substring(0, e.target.selectionStart)
				);
				suggestions = Array.isArray(_) ? _ : suggestions;
			}}
			on:focus
			on:input
			on:change={onChange}
			on:change
			on:blur={onBlur}
			on:blur
			on:mouseenter
			on:mouseleave
			on:keydown
			on:keydown={async function (event) {
				if (event.key === " " && event.ctrlKey && !!getAutoCompletion()) {
					if (getAutoCompletionSingle) {
						value = `${suggestions_prepend}${await lib.selectObjectPath(getAutoCompletion(), genAutoCompletionButtons)}`;
					} else {
						value =
							`${" ".if(!value.endsWith(" "))}${suggestions_prepend}${await lib.selectObjectPath(
								getAutoCompletion(),
								genAutoCompletionButtons
							)}` || "";
					}
					dispatch("autoCompletionDone", { value });
				}
			}}
			use:defUndef
			bind:this={input}
			{...{ type, ...(disabled ? { disabled: true } : {}) }}
			class="form-control"
			class:is-invalid={required && !value}
			{id}
			placeholder={label}
			value={typeof value === "number" ? String(value) : value || ""}
			{list}
			{name}
			{min}
			{max}
		/>
		<label for={id}>{label}</label>
		<ButtonGroup
		buttons={[
			// @ts-ignore
			lib.button_helpers.help({ short: help?.short, long: help?.long }),
			// @ts-ignore
		]}
	/>
	</div>
	<div class="d-flex">
		{#each [...(helpers || []), helper || false, anyobj].filter(Boolean) as helpr}
			{#if helpr}
				<BsButton
					icon={helpr.icon}
					icon_abbr={helpr.abbr || helpr.icon_abbr}
					action={(e) => {
						helpr.action(e);
					}}
				/>
			{/if}
		{/each}
	</div>
</div>

{#if isFocused && getAutoCompletion()}
	<div class="list-group">
		<!-- svelte-ignore missing-declaration -->
		{#each new RegExp("\\$\\:[a-z|A-Z|0-9|.|_]*", "g").execAll(lib.matchType(value, "string", "")) as autocompletion}
			<div class="list-group-item">
				<BsButton
					mousedown
					text={autocompletion[0]}
					action={async () => {
						const newValue = `${suggestions_prepend}${await lib.selectObjectPath(
							getAutoCompletion(),
							genAutoCompletionButtons,
							suggestions_prepend ? autocompletion[0].split(suggestions_prepend)[1] : autocompletion[0]
						)}`;
						value =
							value.slice(0, autocompletion.index) + newValue + value.slice(autocompletion.index + autocompletion[0].length);
					}}
				/>
			</div>
		{/each}
	</div>
{/if}

{#if isFocused && Array.isNotEmpty(suggestions)}
	<div class="list-group suggestions" use:useTop>
		{#each suggestions as sug, i}
			<div
				class="list-group-item"
				class:active={suggestionsPointer === i}
				style="cursor: pointer;"
				on:mousedown={() => {
					suggestionsPointer = i;
					applySuggestion();
				}}
				on:mouseenter={() => {
					suggestionsPointer = i;
				}}
				on:mouseleave={() => {
					// if (suggestionsPointer === i) {
					// 	suggestionsPointer = -1;
					// }
				}}
			>
				<!-- svelte-ignore missing-declaration -->
				{#if typeof sug === "string"}
					{sug}
				{:else if lib.isObject(sug) && (!!sug.value || sug.value === null) && typeof sug.label === "string"}
					{sug.label}
				{:else}
					invalid sug
					<pre><code>{JSON.pretty(sug)}</code></pre>
				{/if}
			</div>
		{/each}
	</div>
{/if}

{#if Array.isNotEmpty(datalist_options)}
	<datalist id={list}>
		{#each datalist_options as item}
			{#if typeof item === "string"}
				<option value={item}>{item}</option>
			{:else}
				<option value={item.value}>{item.label}</option>
			{/if}
		{/each}
	</datalist>
{/if}

<style lang="scss">input:disabled {
  color: #666;
}

.suggestions {
  position: fixed;
  z-index: 10000;
}

label {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  color: var(--bs-body-color) !important;
  opacity: 0.65;
}

input::placeholder {
  color: transparent !important;
  opacity: 0.65;
}</style>
