<script lang="ts">import BsButton from "./BsUI/BsButton.svelte";
export let vertical = false;
export let buttons;
export let min_to_dropdown = false;
</script>

{#if typeof min_to_dropdown === "number" && buttons.length > min_to_dropdown}
	<div class="dropdown hide_down_arrow">
		<button
			class="btn btn-secondary dropdown-toggle"
			type="button"
			data-bs-toggle="dropdown"
			aria-expanded="false"
			on:click|preventDefault|stopPropagation
		>
		<!--  -->
		</button>
		<ul class="dropdown-menu">
			{#each (buttons || []).filter(Boolean) as button}
				<div class="dropdown-item">
					<BsButton {...button} />
				</div>
			{/each}
		</ul>
	</div>
{:else}
	<div class="d-flex" class:flex-column={vertical}>
		{#each (buttons || []).filter(Boolean) as button}
			<BsButton {...button} />
		{/each}
	</div>
{/if}

<style lang="scss">.dropdown .dropdown-item, .dropdown .dropdown-menu {
  min-width: unset;
}</style>
