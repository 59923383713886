import axios from "axios";
import _sort from "lodash/sortBy";
import { get_store_value } from "svelte/internal";
import { DEBUG_APP } from "src/store";
import ContextMenu from "src/components/ContextMenu.svelte";
import { cloneDeep, fn, get } from "lodash";
import type { JSONSchema7 } from "json-schema";
import * as jdp from "jsondiffpatch";

import configureMeasurements, { allMeasures } from "convert-units";
import { APP_CONFIG, REAL_APP_CONFIG, ROUTES } from ".";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import jsonpath from "jsonpath";

import * as graphql from "graphql";
import Html from "src/components/Html.svelte";

export { graphql };

export const convert = configureMeasurements(allMeasures);

/** @typedef {*} JsonComp */

/**
 * Format bytes as human-readable text.
 * @param {number} bytes Number of bytes
 * @param {boolean} [si=false] - True to use metric (SI) units, aka powers of 1000. False to use binary (IEC), aka powers of 1024.
 * @param {number} dp Number of decimal places to display.
 * @return Formatted string.
 * @author https://stackoverflow.com/a/14919494
 * @method humanFileSize
 * @memberof lib
 */
export function humanFileSize(bytes, si = false, dp = 1) {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + " B";
	}

	const units = si
		? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
		: ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

	return bytes.toFixed(dp) + " " + units[u];
}

/**
 * Open a specific URL in a new tab
 * @param {string} url Full URL to open
 * @returns {void}
 * @method openInNewTab
 * @memberof lib
 */
export function openInNewTab(url: string, download?: string) {
	const a = document.createElement("a");
	a.style.display = "none";
	document.body.appendChild(a);
	a.onclick = (e) => {
		a.remove();
	};
	if (download) {
		axios
			.get(url, {
				responseType: "blob"
			})
			.then(({ data }) => {
				a.href = URL.createObjectURL(data);
				a.download = download;
				a.click();
			})
			.catch(console.error);
	} else {
		a.href = url;
		a.target = "_blank";
		a.click();
	}
}

/**
 * Get the tabulator instance
 * @param {string} id Tabulator `instance_id`
 * @returns {Tabulator} Tabulator Instance
 * @memberof lib
 */
export function getTabulatorInstance(id: string): Tabulator | null {
	return window.tabulator_instances[id] || null;
}

/**
 *	Async Timeout
 * @param {number} [ms=1e3] Ms to wait
 * @returns {Promise<void>}
 * @method humanFileSize
 * @memberof lib
 */
export async function wait(ms: number = 1e3): Promise<void> {
	return new Promise((resolve) => {
		const to = setTimeout(() => {
			clearTimeout(to);
			resolve();
		}, ms);
	});
}

/**
 * In-function waiter
 *
 * @example
 *
 * async function doSomething() {
 * 	for (const item of ['a', 'b', 'c']) {
 * 		const { promise, stop } = lib.waitForFunctionCall()
 * 		// ...
 * 		const button = document.getElementById('button')
 * 		button.innerHTML = 'This loop will not continue until continue is pressed'
 * 		button.onclick = e => {
 * 			stop();
 * 		}
 * 		await promise; // Yes, this is the correct syntax. Do not call promise as a function!
 * 	}
 * }
 *
 * @returns {{promise:Promise;stop():void}}
 * @method waitForFunctionCall
 * @memberof lib
 */
export function waitForFunctionCall() {
	let stopper;
	return {
		promise: new Promise((resolve) => {
			stopper = resolve;
		}),
		stop() {
			stopper();
		}
	};
}

export async function animateVariable<T = any>(
	setter: (value: T) => void,
	oriValue: T,
	values: T[],
	intervals: number = 25
) {
	for (const value of values) {
		setter(value);
		await wait(intervals);
	}
	setter(oriValue);
}

export function getForeignTableName<T extends TschemaTableName>(
	table: T | string,
	field: string
): TschemaTableName | null {
	// @ts-ignore
	return lib.schema[table][field][lib.schema[table][field].type].table || null;
}

export function getEnv<T>(name: ENV_VAR, or?: T): string | T | undefined {
	const out = process.env[`${process.env.NODE_ENV.toUpperCase()}_${name}`] || process.env[name] || or || undefined;
	return out;
}

export async function tabulatorSort<T extends TschemaTableName = any>(
	data: TschemaData_Table<T>[],
	instance: Tabulator
) {
	for (const sorter of instance.getSorters()) {
		data = _sort(data, sorter.column) as typeof data;
		if (sorter.dir === "desc") {
			data = data.reverse();
		}
	}

	return data;
}

export function line() {
	var e = new Error();
	if (!e.stack)
		try {
			// IE requires the Error to actually be throw or else the Error's 'stack'
			// property is undefined.
			throw e;
		} catch (e) {
			if (!e.stack) {
				return 0; // IE < 10, likely
			}
		}
	var stack = e.stack.toString().split(/\r\n|\n/);
	// We want our caller's frame. It's index into |stack| depends on the
	// browser and browser version, so we need to search for the second frame:
	var frameRE = /:(\d+):(?:\d+)[^\d]*$/;
	do {
		var frame = stack.shift();
	} while (!frameRE.exec(frame) && stack.length);
	return frameRE.exec(stack.shift())[1];
}

const opMap = {
	$lt: "<",
	$gt: ">",
	$lte: "<=",
	$neq: "!==",
	$eq: "==="
};

const conMap = {
	$or: "||",
	$and: "&&"
};

type xfCondition_op = keyof typeof opMap;
type xfCondition_con = keyof typeof conMap;

type xfCU1 = {
	[Property in xfCondition_con]?: (xfCondition | xfCU2)[];
};

type xfCU2 = {
	[key: string]: {
		[Property in xfCondition_op]?: string | number | boolean | null;
	};
};

declare global {
	type xfCondition = xfCU1 | xfCU2;
}

export function functionaliseStatementRaw(statements): string {
	function processThen(statement) {
		return `Object.assign(out, ${JSON.stringify({
			...(statement?.then?.apply || {}),
			meta: (statement?.then?.assign || []).filter(({ modifier }) => !modifier),
			...(statement?.then?.assign || [])
				.filter(({ modifier }) => !!modifier)
				.reduce((p, { modifier, property, value }) => {
					p[`${modifier}_${property}`] = value;
					return p;
				}, {})
		})})`;
	}
	function processStatement(statement) {
		return statement.conditions
			.map(
				({ main_operator, var_mod_left, var_left, operator, var_right, var_mod_right }) =>
					`${`${main_operator} `.if(!!main_operator)}(${var_mod_left || ""}${"_".if(!!var_mod_left)}${var_left || ""} ${
						operator || ""
					} ${var_mod_right || ""}${"_".if(!!var_mod_right)}${var_right}) `
			)
			.join("");
	}
	// last statement is a else
	const last = statements.pop();

	let out = ``;

	for (const statement of statements) {
		const processed = processStatement(statement) as string;
		out += `if (${processed.replace(/ /g, "").length > 0 ? processed : "false"}) {\n\t${processThen(statement)}\n} else `;
	}

	out += `{\n\t${processThen(last)}\n}`;
	return `const { $$INJECT_CONSTANTS } = arguments[0];\nconst out = {};\n${out}\nreturn out`;
}

export function functionaliseStatement<T = any>(statement: any[]): () => T {
	const raw = functionaliseStatementRaw(statement);
	return (args = {}) => {
		const out = raw.replace("$$INJECT_CONSTANTS", Object.keys(args).join(","));
		if (get_store_value(DEBUG_APP)) {
			console.log(out);
		}
		return (new Function(out) as any)(args);
	};
}

export function getBackcastAvg(arr, precision = 5) {
	let count = 0;
	let total = 0;
	for (let i = arr.length - 1; i >= 0; i--) {
		if (count < precision) {
			total += arr[i];
			count++;
		}
	}
	return total / count;
}

/**
 * @class
 * @name ClassWatcher
 * @memberof lib
 */
export class ClassWatcher {
	private lastClassState: boolean;
	private observer: MutationObserver;

	/**
	 * @constructor
	 * @param {HTMLElement} targetNode
	 * @param {string} classToWatch
	 * @param {Function} classAddedCallback
	 * @param {Function} classRemovedCallback
	 */
	constructor(
		private readonly targetNode: HTMLElement,
		private readonly classToWatch: string,
		readonly classAddedCallback: () => void,
		readonly classRemovedCallback: () => void
	) {
		this.targetNode = targetNode;
		this.classToWatch = classToWatch;
		this.classAddedCallback = classAddedCallback;
		this.classRemovedCallback = classRemovedCallback;
		this.lastClassState = targetNode.classList.contains(this.classToWatch);

		this.observer = new MutationObserver(this.mutationCallback);
		this.observer.observe(this.targetNode, { attributes: true });
	}

	/**
	 * Stop watching
	 * @method disconnect
	 * @returns {void}
	 */
	disconnect() {
		this.observer.disconnect();
	}

	mutationCallback = (mutationsList) => {
		for (let mutation of mutationsList) {
			if (mutation.type === "attributes" && mutation.attributeName === "class") {
				let currentClassState = mutation.target.classList.contains(this.classToWatch);
				if (this.lastClassState !== currentClassState) {
					this.lastClassState = currentClassState;
					if (currentClassState) {
						this.classAddedCallback();
					} else {
						this.classRemovedCallback();
					}
				}
			}
		}
	};
}

function parseQuery(queryString) {
	var query = {};
	var pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
	for (var i = 0; i < pairs.length; i++) {
		var pair = pairs[i].split("=");
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
	}
	return query;
}

/**
 * @deprecated no longer in use
 * @returns {stringOfstring}
 * @method gql
 * @memberof lib
 */
export function getWindowParams(): stringOfstring {
	return parseQuery(window.location.search);
}

/**
 * Getting svelte store value
 * @param {Writable<any>} store Svelte store
 * @returns {any} Store Value
 * @deprecated Replacing in favour of {@link lib.getStoreValue}
 * @method sv
 * @memberof lib
 */
export function sv<T = any>(store: Writable<T>): T {
	let val;
	store.subscribe((state) => {
		val = state;
	})();
	// console.log("store value", val);
	return val;
}

/**
 * Get svelte store value
 * @param {Writable<any>} store
 * @param {boolean} [safe=false] If to throw error if `store` is not a svelte store
 * @returns {void|any} svelte store
 * @method getStoreValue
 * @memberof lib
 */
export function getStoreValue<T = any, Safe extends boolean = false>(
	store: Safe extends false ? Readable<T> : Readable<T> | any,
	safe = false
): T {
	if (!lib.isSvelteStore(store)) {
		if (safe) {
			throw new Error(`is not a svelte store!`);
		} else {
			// console.warn(`is not a svelte store`);
			return;
		}
	}
	return svelte.store.get(store);
}

/**
 * Proxy for nice formatting on VS Code
 * @param {TemplateStringsArray} inp
 * @returns {string}
 * @method gql
 * @memberof lib
 */
export function gql(inp: TemplateStringsArray, ...values: any[]): string {
	return inp
		.map((str, i) => {
			return str + (values[i] || "");
		})
		.join("");
}

export function getStackTrace(): string {
	var obj: { stack: string } = {
		stack: ""
	};
	Error.captureStackTrace(obj, getStackTrace);
	return obj.stack;
}

export function storeAssign(store: Writable<any>, property, value) {
	store.update((state) => {
		Object.assign(state, { [property]: value });
		return state;
	});
}

/**
 * Execute JS string that start with `eval:`
 * @example
 *
 * const out = { a: lib.safeEval("eval:1 + 1") }
 * console.log(out)
 * // prints: { a: 2 }
 *
 * @param {string} str String to potentially evaluate
 * @param {Object.<string, any>} args Object keys that will be available, similar to the `with (args) {  }` syntax
 * @returns {any} Evaluated string
 * @method safeEval
 * @memberof lib
 */
export function safeEval<T = any>(str: string, args: stringOfAny): T | string {
	try {
		if (str.startsWith("eval:")) {
			str = str.replace("eval:", "");
		} else if (str.startsWith("jsfunction:")) {
			str = str.replace("jsfunction:", "");
		} else return str;
		const command = `const { ${Object.keys(args)
			.map((key) => key)
			.join(", ")} } = arguments[0]; // console.log(arguments); \n return ${str}`;
		// console.log(command);
		return new Function(command)(args);
	} catch (error) {
		console.error({ str, args, error });
		return str;
	}
}

/**
 * Same as `safeEval`, except it loops through Object keys
 * @example
 *
 * const withs = {
 * 	withProperty: {
 * 		sumOfOneAndTwo: 3
 * 	}
 * }
 *
 * let obj = {
 * 	a: 2,
 * 	b: "eval:withProperty.sumOfOneAndTwo",
 * 	c: "eval:100/10"
 * }
 *
 * obj = lib.safeEvalJsonComp(obj, withs)
 *
 * console.log(obj)
 *
 * // outputs: { a: 2, b: 3, c: 10 }
 *
 * @see {@link lib.safeEval}
 * @param {JsonComp} obj Object
 * @param {Object.<string, any>} withs Object keys that will be available, similar to the `with (args) {  }` syntax
 * @returns {typeof obj} Original Object
 * @method safeEvalJsonComp
 * @memberof lib
 */
export function safeEvalJsonComp<T extends JsonComp = JsonComp>(obj: T, withs: stringOfAny): T {
	if (!obj) return obj;
	if (lib.isEvalType(obj)) {
		// return lib.safeEval2(obj, withs);
	}
	let out = lib.xf.clone(obj);
	if (["number", "boolean"].includes(typeof out) || out === null) {
		// do nothing
	} else {
		if (typeof out === "string") {
			// @ts-ignore
			out = lib.safeEval(out, withs);
		} else if (typeof out === "object") {
			for (const key in out) {
				// @ts-ignore
				out[key] = safeEvalJsonComp(out[key], withs);
			}
		}
	}
	return out;
}

export async function getScript(src: string) {
	if (!window.importedScripts) {
		window.importedScripts = [];
	}

	if (window.importedScripts.includes(src)) {
		return;
	} else {
		window.importedScripts.push(src);
	}

	const { data } = await axios.get(src);
	eval(data);
	return;
}

export function contextMenu(
	e: Event,
	options: {
		text?: string;
		icon?: string;
		action?: (e: Event) => void;
		disabled?: boolean;
	}[]
) {
	if (!options || !(Array.isArray(options) && options.length > 0)) {
		return;
	}
	e.preventDefault();
	e.stopImmediatePropagation();
	// @ts-ignore
	const { x, y } = e;
	const wrapper = document.createElement("div");
	document.body.appendChild(wrapper);

	const cm = new ContextMenu({
		target: wrapper,
		// @ts-ignore
		props: { options, e, x, y, destroy }
	});

	let called = false;
	function destroy() {
		try {
			if (called) {
				console.error("CTX MENU DESTROYER CALLED MULTIPLE TIMES");
				// debugger;
			}
			cm.$destroy();
			wrapper.remove();
			called = true;
		} catch (error) {}
	}

	wrapper.tabIndex = 1;
	wrapper.focus({
		preventScroll: true
	});

	setTimeout(() => {
		wrapper.onblur = () => {
			destroy();
		};
	});
}

export function accessFromString(o: any, s: string) {
	if (s.startsWith("$")) {
		return json_path_extract(o, s);
	}
	s = s.replace(/\[(\w+)\]/g, ".$1");
	s = s.replace(/^\./, "");
	if (s.length === 1) return o;
	var a = s.split(".");
	for (var i = 0, n = a.length; i < n; ++i) {
		if (!o) return;
		var k = a[i];
		if (k in o) {
			o = o[k];
		} else {
			return;
		}
	}
	return o;
}

export function assignFromString(obj: any, str: string, val: any): void {
	str = str.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
	str = str.replace(/^\./, ""); // strip a leading dot
	if (str.length === 1) return obj;
	const splat = str.split(".");
	const lastPart = splat.pop();
	let ref = obj;
	for (const i in splat) {
		if (!ref || !ref[splat[i]]) return;
		ref = ref[splat[i]];
	}
	ref[lastPart] = val;
}

export function emptyObjectFromString(str, value) {
	const out = {};
	const path = str.replace(/\[(\w+)\]/g, ".$1").split(".");
	let ref = out;
	for (const i in path) {
		if (+i != path.length - 1) {
			ref[path[i]] =
				// !isNaN(path[i]) ?
				{};
			//   : [];
		} else {
			ref[path[i]] = value;
		}
		ref = ref[path[i]];
	}
	return out;
}

export function destructureData(obj, path = []) {
	const out = [];

	for (const key in obj) {
		if (Array.isArray(obj[key])) {
			for (const index in obj[key]) {
				out.push(...destructureData(obj[key][index], [...path, key, index]));
			}
		} else if (obj[key] === null || ["undefined", "string", "number", "boolean"].includes(typeof obj[key])) {
			out.push([...path, key].join("."));
		} else {
			out.push(...destructureData(obj[key], [...path, key]));
		}
	}

	return out;
}

/**
 * Mini search tool
 * @param {string} queryString Search for string
 * @param {string[]} items Search in those strings
 * @returns {string[]} Matched strings
 * @method filterString
 * @memberof lib
 */
export function filterStrings<T extends string = string>(queryString: string, items: T[], itemsProp?: string): T[] {
	const prepareStr = (str: string) => str.toLocaleLowerCase().replace(/[^A-Z^a-z^0-9]/g, "");

	return items.filter((str) => {
		const a = prepareStr(itemsProp ? str[itemsProp] : str);
		const b = prepareStr(queryString);
		return a.match(b);
	});
}

/**
 * Verifies argument is a svelte store
 * @param {Writable<any>|any} ps PotentialStore
 * @returns {boolean} Whether it is a svelte store
 * @method isSvelteStore
 * @memberof lib
 *
 * @example
 *
 * const data1 = writable();
 * const data2 = writable({});
 * const data3 = {};
 *
 * isSvelteStore(data1); // true
 * isSvelteStore(data2); // true
 * isSvelteStore(data3); // false
 */
export function isSvelteStore(ps: Writable<any> | any): boolean {
	let isStore = false;
	if (ps) {
		if ("subscribe" in ps) {
			if (typeof ps.subscribe === "function") {
				isStore = true;
			}
		}
	}
	// console.log({ ps, isStore });
	return isStore;
}

/** @author {@link https://stackoverflow.com/a/51390763} */
type DefinitelyTruthy<T> = false extends T
	? never
	: 0 extends T
	? never
	: "" extends T
	? never
	: null extends T
	? never
	: undefined extends T
	? never
	: T;

/**
 * Callback proxy for nice iifes
 * Works only if `arg` is truthy
 * @param {any} arg
 * @param {(arg: any)=>void} callback
 * @returns {void} Nothing
 * @method cb
 * @memberof lib
 */
export function cb<T = any>(arg: T, callback: DefinitelyTruthy<T> extends never ? never : (arg: T) => void): void {
	if (arg) callback(arg);
}
declare global {
	interface Window {
		importedScripts: string[];
	}
}

export function isInstanceOf<A = any, B = any>(objA: A | any, objB: B | any): boolean {
	return objA instanceof objB;
}

export function JsonCompRemoveEmptyStrings(obj: JsonComp) {
	if (typeof obj === "object" && obj !== null) {
		for (const key in obj) {
			if (obj[key] === "") {
				delete obj[key];
			} else {
				JsonCompRemoveEmptyStrings(obj[key]);
			}
		}
	}
}

export function meet(conditions: (boolean | (() => boolean))[]): boolean {
	for (const condition of conditions) {
		if (typeof condition === "function") {
			if (!condition()) {
				return false;
			}
		} else {
			if (!condition) {
				return false;
			}
		}
	}
	return false;
}

export function selectObjectPath(
	obj: JsonComp | any,
	buttons: (resolver: (out: string) => void) => any[] = () => [],
	property?: string
): Promise<string> {
	return new Promise(async function (resolve, reject) {
		const { default: component } = await import("/src/components/JsonSelector.svelte");
		lib.dialog({
			component,
			props: {
				obj,
				property,
				buttons: [
					...(typeof buttons === "function" ? buttons(resolve) : []),
					{
						label: "Cancel",
						action() {}
					},
					{
						label: "Set",
						action: resolve
					}
				]
			}
		});
	});
}

export function setStoreOriginalValues(store: Writable<any>, { ...data }) {
	const clone = { ...{ ...data } };
	Object.freeze(clone);
	store.update((state) => {
		Object.defineProperties(state, {
			get_original_value: {
				value() {
					// console.log("retrieving ori val");
					return clone;
				},
				configurable: true
			},
			original_value: {
				get() {
					return clone;
				},
				configurable: true
			},
			reset_original_value: {
				value() {
					alert("reset called");
					store.update((state) => {
						const oriVal = { ...lib.xf.clone(state) };
						Object.defineProperty(state, "original_value", {
							get() {
								return oriVal;
							},
							configurable: true
						});
						return state;
					});
				},
				configurable: true
			}
		});
		return state;
	});
}

export function notIn(prop: string, obj: object): boolean {
	return !(prop in obj);
}

export function safeFn<T extends (...args: any) => any = (...args: any) => any>(
	fn: T,
	...params: Parameters<T>
): ReturnType<T> | void {
	if (typeof fn === "function") {
		// @ts-ignore
		return fn(...params);
	}
}

export function uid(base: number = 16): string {
	// @ts-ignore
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & ((base - 1) >> (c / 4)))).toString(base)
	);
}

export function uidShort(base: number = 16): string {
	// @ts-ignore
	return ([1e7] + 1e11).replace(/[018]/g, (c) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & ((base - 1) >> (c / 4)))).toString(base)
	);
}

export function getUidChecksum(uid: string, base = 0xf) {
	let total = Infinity;
	let currentUid = uid + "";

	do {
		// console.log(currentUid);
		let newTotal = 0;
		for (const letter of currentUid) {
			newTotal += Number(`0x${letter}`);
		}
		total = newTotal;
		currentUid = Number(`0x${total}`).toString(base);
	} while (total > base);

	return total;
}

export function tacticalUid(checksum = 0) {
	let valid = checksum <= 0;
	let uid = "";

	// let uidSetCount = 0;
	function setNewUid() {
		uid = uidShort();
		// uidSetCount++;
		while (!uid[0].match(/^[a-z]/)) {
			// uid[0] = lib.uidShort()[3];
			uid = uidShort()[3] + uid.substr(1);
			// uidSetCount++;
		}
	}

	setNewUid();

	while (!valid) {
		setNewUid();
		if (checksum > 0) {
			valid = getUidChecksum(uid) === checksum;
		}
	}

	// console.log(uidSetCount);
	return uid;
}

export function valueOf<O extends Object | String | Number | Boolean | { valueOf?(): any } = any>(
	obj: O
): ReturnType<O["valueOf"]> {
	return (!!obj || ["string", "number", "object", "boolean"].includes(typeof obj)) &&
		typeof obj["valueOf"] === "function"
		? obj["valueOf"]()
		: obj;
}

export async function asyncValueOf<O extends Object | String | Number | Boolean | { valueOf?(): any } = any>(
	obj: O
): Promise<ReturnType<O["valueOf"]>> {
	return await lib.valueOf(obj);
}

export function isEvalType(obj: any): boolean {
	if (typeof obj === "object" && !!obj) {
		if ("type" in obj) {
			if (obj["type"] === "eval") {
				return true;
			}
		}
	}
	return false;
}

export function matchType<A extends any = any, B extends any = undefined>(
	obj: A,
	type: typeOfTypes,
	or: B = undefined,
	warn: boolean = false
): A | B {
	if (typeof obj !== type && warn) {
		// console.warn(obj, "is not", type, "fallbacking to", or);
	}
	return typeof obj === type ? obj : or;
}

export function safeEval2<A extends TsafeEval2 | any = any>(obj: A | any, withs: stringOf): A | unknown {
	if (!isEvalType(obj)) return lib.safeEvalJsonComp(obj, withs);

	if (withs?.ctxs instanceof Map) {
		withs.ctxs = withs.ctxs.toObject();
	}

	if (obj.action === "property") {
		const { from, property, or } = obj.actionArgs;
		if (!from || !property) {
			return lib.safeEvalJsonComp(obj ?? or, withs);
		}
		if (from === "store_value") {
			return lib.getStoreValue(withs.getStore(property));
		}

		return lib.safeEvalJsonComp(lib.accessFromString(withs[from], property), withs) || or;
	} else if (obj.action === "element_value") {
		return withs.getElementValue(obj?.actionArgs?.elementId);
	} else if (obj.action === "datasource") {
		const sv = lib.getStoreValue(withs.getStore(obj.actionArgs.bindStore));
		if (obj.actionArgs.bindProperty) {
			return lib.accessFromString(sv, obj.actionArgs.bindProperty);
		} else return sv;
		// console.log(obj, withs);
		// debugger;
	} else if (obj.action === "from_input") {
		// console.log(obj, withs);
		const { input: prop } = obj.actionArgs;
		// if (lib.isSvelteStore(withs?.ctxs?.PAYLOAD)) {
		// 	const payload = lib.getStoreValue(withs.ctxs.PAYLOAD);

		// 	// console.log({ obj, withs, v: payload[prop]?.value });

		// 	if (payload[prop]?.value) {
		// 		return payload[prop]?.value;
		// 	}
		// 	// if (withs[obj.property]) {
		// 	// 	return lib.getStoreValue(withs.ctxs.PAYLOAD)
		// 	// }
		// }
		// if ()
		console.log({ obj, withs });
		return;
	} else {
		console.log("implenent new acion", obj.action);
		return lib.safeEvalJsonComp(obj, withs);
	}
}

export function safeEval2Deep(obj: any, withs: stringOf) {
	if (isEvalType(obj)) {
		return safeEval2(obj, withs);
	} else if (Array.isArray(obj)) {
		const out = [];
		for (const item of obj) {
			out.push(safeEval2Deep(item, withs));
		}
		return safeEvalJsonComp(out, withs);
	} else if (typeof obj === "object" && obj !== null) {
		const out = {};
		for (const key in obj) {
			out[key] = safeEval2Deep(obj[key], withs);
		}
		return out;
	} else {
		return safeEvalJsonComp(obj, withs);
	}
}

export function fullyEvaled(obj): boolean {
	if (lib.isEvalType(obj)) {
		return false;
	}

	if (typeof obj === "object" && obj !== null) {
		for (const key in obj) {
			if (!fullyEvaled(obj[key])) {
				return false;
			}
		}
	}

	return true;
}

interface walk_arg {
	entry(
		args: Partial<{
			obj: any;
			type: "Array" | "Object" | typeOfTypes;
			parent;
			grandParent;
			path: string[];
			ref;
			history: string[];
			stop(): void;
			set(value: any): void;
			remove(): void;
		}>
	): void;
	ignoreTypeOf: Array<"string" | "number" | "undefined" | "bigint" | "boolean" | "function" | "symbol" | "object">;
	ignoreType: string[];
	ignoreKeys: string[];
	path: string[];
	parent: any;
	grandParent: any;
	index: any;
	key: any;
	ref: any;
	history: string[];
	set(value: any): void;
	remove(): void;
	arrayOnlyFirst: boolean;
	_recursive_track: WeakSet<any>;
	max_depth?: number;
}

class STOP_ERROR extends Error {}

export function walk(
	obj: any,
	{
		entry,
		ignoreTypeOf = ["string", "number", "undefined", "bigint", "boolean", "function", "symbol", "object"],
		ignoreType = [],
		ignoreKeys = [],
		path = [],
		parent = null,
		grandParent = null,
		index = null,
		key = null,
		ref = {},
		history = [],
		set = lib.xf.selfop,
		remove = () => null,
		arrayOnlyFirst = false,
		_recursive_track = new WeakSet(),
		max_depth = -1
	}: Partial<walk_arg>,
	top = true
): void {
	function stop() {
		throw new STOP_ERROR();
	}
	if (max_depth > -1 && history.length > max_depth) {
		stop();
	}
	try {
		if (!ignoreType.includes("Array") && Array.isArray(obj)) {
			if (_recursive_track.has(obj)) {
				return;
			} else {
				_recursive_track.add(obj);
			}
			entry({
				obj,
				type: "Array",
				parent,
				grandParent,
				path,
				ref,
				history,
				stop,
				set,
				remove
			});
			for (const index in obj) {
				if (arrayOnlyFirst ? index != "0" : false) continue;
				walk(
					obj[index],
					{
						ignoreKeys,
						ignoreType,
						ignoreTypeOf,
						entry,
						parent: obj,
						grandParent: parent,
						index: +index,
						path: [...path, index],
						ref,
						history: [...history, obj],
						set(value) {
							obj[index] = value;
						},
						remove() {
							obj.splice(+index, 1);
						},
						arrayOnlyFirst,
						_recursive_track
					},
					false
				);
			}
		} else if (!ignoreType.includes("Object") && typeof obj === "object" && obj !== null) {
			if (_recursive_track.has(obj)) {
				return;
			} else {
				_recursive_track.add(obj);
			}
			entry({
				obj,
				type: "Object",
				parent,
				grandParent,
				index,
				key,
				path,
				ref,
				history,
				stop,
				set,
				remove
			});
			for (const key in obj) {
				if (!ignoreKeys.includes(key)) {
					walk(
						obj[key],
						{
							ignoreKeys,
							ignoreType,
							ignoreTypeOf,
							entry,
							parent: obj,
							key,
							grandParent: parent,
							path: [...path, key],
							history: [...history, obj],
							ref,
							set(value) {
								obj[key] = value;
							},
							remove() {
								delete obj[key];
							},
							arrayOnlyFirst,
							_recursive_track
						},
						false
					);
				}
			}
		} else if (!ignoreTypeOf.includes(typeof obj)) {
			entry({
				obj,
				type: typeof obj,
				parent,
				grandParent,
				path,
				stop,
				ref,
				history,
				set,
				remove
			});
		}
	} catch (error) {
		if (error instanceof STOP_ERROR) {
			if (!top) throw error;
		} else throw error;
	}
}

export function guessTn([...path] = []) {
	if (path.length < 1) {
		return null;
	} else if (path.length === 1) {
		path[0] = path[0].replace(/(^(\$)?\[")|"\]/g, "");
		if (path[0] in lib.schema) {
			return path[0];
		} else {
			return null;
		}
	} else {
		path = path.filter(isNaN);
		let last_tn;
		for (let i = 0; i <= path.length - 1; i++) {
			const tn = path[i].replace(/(^(\$)?\[")|"\]/g, "");
			if (tn in lib.schema) {
				last_tn = tn;
			} else {
				if (last_tn && tn in lib.schema[last_tn]) {
					last_tn = lib.schema[last_tn][tn][lib.schema[last_tn][tn].type].table;
				}
			}
		}
		return last_tn || null;
	}
}

export function discover<T = any>(
	obj: any,
	cb: (obj: any, ...args: any[]) => T,
	config: {
		ignoreTypeOf?: string[];
		ignoreType?: any[];
	} = {}
): T {
	let found = undefined;

	lib.walk(obj, {
		...config,
		entry({ obj, stop }) {
			found = cb(obj, ...arguments) || undefined;
			if (found) {
				stop();
			}
		}
	});

	return found;
}

export function discoverAll<T = any>(
	obj: any,
	cb: (obj: any, ...args: any[]) => T,
	config: {
		ignoreTypeOf?: string[];
		ignoreType?: any[];
		ignoreKeys?: string[];
	} = {}
): T[] {
	const found = [];

	lib.walk(obj, {
		...config,
		entry({ obj }) {
			const newlyFound = cb(obj, ...arguments) || undefined;
			if (newlyFound) {
				found.push(newlyFound);
			}
		}
	});

	return found;
}

export function _if<A = any, B = any>(condition, output) {
	return {
		_else_if(condition1, output1) {
			return {
				_else(output2) {
					return condition ? output : condition1 ? output1 : output2;
				}
			};
		},
		_else(output2) {
			return condition ? output : output2;
		}
	};
}

export function isObject(obj: any): boolean {
	return typeof obj === "object" && obj !== null;
}

export function isObjectOrArray(obj: any): boolean {
	return Array.isArray(obj) || lib.isObject(obj);
}

export function getEnums(path: string[]): null | string[] {
	try {
		const newPath = [...path];
		do {
			const clonePath = [...newPath];
			const field = clonePath.pop();

			const tN = lib.guessTn(clonePath);

			if (lib.tryOr(() => lib.schema[tN][field].type === "enum", false)) {
				return lib.schema[tN][field].enum.values;
			}

			newPath.pop();
		} while (newPath.length > 1);
		return null;
	} catch (error) {
		return null;
	}
}

export function proxyCachedStore<T = any>(
	getter: (property: string, assign: (value: T) => void, neverAssign: () => void) => void,
	config: {
		processProp?: (str: string) => string;
		storeArgs?: SvelteExtended.store_Writable_args<T>;
	} = {
		processProp: lib.xf.selfop
	}
): SvelteExtended.store_Writable<stringOf<T | null>> & {
	reset(): void;
	waitFor(id): Promise<void>;
	NEVER_ASSIGNED: Readable<string[]>;
} {
	const resed = [];
	const noRes = [];
	const requested = [];
	const waiters = {};
	const target = {};

	const NEVER_ASSIGNED = svelte.store.writable<string[]>([]);

	const {
		subscribe,
		set, //: store_set,
		update
	} = svelte.store.writable<stringOf<T | null>>(
		new Proxy(target, {
			get(t, prop: string) {
				processWaiters();
				prop = config.processProp(prop);
				if (t[prop]) {
					return t[prop];
				}
				if (!prop || prop === "undefined" || typeof prop !== "string" || noRes.includes(prop) || requested.includes(prop)) {
					return null;
				}

				requested.push(prop);

				getter(
					prop,
					(value) => {
						t[prop] = value;
						resed.push(prop);
						update(lib.xf.selfop);
						processWaiters();
					},
					() => {
						noRes.push(prop);
						lib.modStore(NEVER_ASSIGNED, (pages) => {
							pages.push(prop);
						});
						update(lib.xf.selfop);
						processWaiters();
					}
				);

				return null;
			}
		})
	);

	function get() {
		return lib.getStoreValue({ subscribe });
	}

	function run_subscribers() {
		set(get());
	}

	function reset() {
		resed.length = 0;
		noRes.length = 0;
		requested.length = 0;
		for (const key in target) {
			delete target[key];
		}
		processWaiters();
		run_subscribers();
	}

	function processWaiters() {
		for (const id in waiters) {
			if (resed.includes(id) || noRes.includes(id)) {
				for (const release of waiters[id]) {
					release();
				}
			}
		}
	}

	window.EVENTS.on("before_initApp", reset);

	return {
		subscribe,
		reset,
		update,
		set,
		get,
		run_subscribers,
		waitFor(id) {
			return new Promise<void>(function (resolve) {
				if (resed.includes(id) || noRes.includes(id)) {
					resolve();
				}
				if (!waiters[id]) waiters[id] = [];
				waiters[id].push(resolve);
				subscribe((state) => state[id]);
			});
		},
		NEVER_ASSIGNED: {
			subscribe: NEVER_ASSIGNED.subscribe
		}
	};
}

export function sync(target, tProp, source, sProp) {
	Object.defineProperty(target, tProp, {
		enumerable: true,
		configurable: true,
		get() {
			return source[sProp];
		},
		set(value) {
			source[sProp] = value;
		}
	});
	return target;
}

export { default as merge } from "lodash/merge";

export function runOnce(expected: () => orArr, callback: () => void): () => void {
	let called = false;

	function isAvailable(debug) {
		let value = lib.tryOr(
			() => expected()
			// @ts-ignore
			// console.warn("failed", { expected }, expected) || false
		);
		if (debug) {
			// console.log({ expected, value });
		}
		if (Array.isArray(value)) {
			return !value.includes(false);
		} else return !!value;
	}

	function call(debug = false) {
		if (!called && isAvailable(debug)) {
			callback();
			called = true;
		}
	}

	return call;
}

export function iife<T extends (...args: any[]) => any>(fn: T): ReturnType<T> {
	return fn();
}

declare global {
	type TsafeEval2 = {
		type: "eval";
		action?: "raw" | string;
		withs?: stringOf;
		args?: stringOf;
		original_value?: any;
	};

	type typeOfTypes = "string" | "bigint" | "boolean" | "function" | "number" | "object" | "symbol" | "undefined";
}

export function denestObjectKeys<T extends stringOf = any>(obj: T): T {
	const out = {} as T;

	for (const key in obj) {
		if (key.includes(".")) {
			lib.merge(out, lib.emptyObjectFromString(key, obj[key]));
		} else {
			out[key] = obj[key];
		}
	}

	return out;
}

export function labelFromLabVal<T = string, V = string, O = any>(
	arr: {
		label: T;
		value: V;
	}[],
	val: V,
	or?: O
): T | undefined | O {
	for (const { value, label } of arr || []) {
		if (value == val) {
			return label;
		}
	}
	return typeof or === "undefined" ? undefined : or;
}

export function parseOrNull(str: string) {
	try {
		return JSON.parse(str);
	} catch (error) {
		return null;
	}
}

export function getObjDiff<T>(
	a: T,
	b: T
): (({ type: "changed"; from: any; to: any } | { type: "added" | "removed"; value: any }) & { path: string[] })[] {
	const out: ReturnType<typeof getObjDiff> = [];

	lib.walk(a, {
		ignoreType: [],
		ignoreTypeOf: [],
		entry({ obj, path }) {
			if (["string", "number", "boolean"].includes(typeof obj)) {
				const refPath = cloneDeep(path);
				const refProp = refPath.pop();
				const bRef = lib.accessFromString(b, refPath.join("."));

				if (lib.isObject(bRef)) {
					if (bRef.hasOwnProperty(refProp)) {
						const bVal = lib.accessFromString(b, path.join("."));
						if (obj != bVal) {
							out.push({
								type: "changed",
								from: cloneDeep(obj),
								to: cloneDeep(bVal),
								path: cloneDeep(path)
							});
						}
					} else {
						out.push({
							type: "removed",
							value: cloneDeep(obj),
							path: cloneDeep(path)
						});
					}
				}
			} else {
				if (Array.isArray(obj)) {
					const arr2 = lib.accessFromString(b, path.join("."));

					if (Array.isArray(arr2)) {
						if (obj.length != arr2.length) {
							// console.log({ obj, arr2 });

							out.push({
								type: "changed",
								from: cloneDeep(obj),
								to: cloneDeep(arr2),
								path: cloneDeep(path)
							});
						}
					} else {
						out.push({
							type: "changed",
							from: cloneDeep(obj),
							to: cloneDeep(arr2),
							path: cloneDeep(path)
						});
					}
				}
			}

			// const bVal = lib.accessFromString(b, path.join("."));
		}
	});

	return out;
}

export function fieldAndFieldDefsLogicallyOrdered<T extends TschemaTableName = any, IS extends boolean = any>(
	tableName: TschemaTableName,
	opts?: {
		stringSeperators?: IS;
		defaults?: boolean;
		fields?: boolean;
		foreigns?: boolean;
		reverses?: boolean;
		order_by?: "major_type" | "groups";
	}
): IS extends true ? (typeof lib.schema)[T] : (typeof lib.schema)[T] & stringOfstring {
	opts ||= {};
	// @ts-ignore
	if (lib.notIn("stringSeperators", opts)) opts.stringSeperators = false;
	if (lib.notIn("defaults", opts)) opts.defaults = true;
	if (lib.notIn("foreigns", opts)) opts.foreigns = true;
	if (lib.notIn("reverses", opts)) opts.reverses = true;
	if (lib.notIn("fields", opts)) opts.fields = true;
	if (lib.notIn("order_by", opts)) opts.order_by = "groups";

	const { defaults, fields, foreigns, reverses, stringSeperators } = opts;

	if (opts.order_by === "major_type") {
		// @ts-ignore
		return Object.entries(
			[
				...["id", "created_at", "created_by", "updated_at", "updated_by"].if(defaults),
				...[
					...["Fields"].if(stringSeperators),
					...Object.keys(lib.schema[tableName])
						.filter((field) => !lib.schema[tableName][field].reverse)
						.filter((field) => !["foreign", "link", "externalLink"].includes(lib.schema[tableName][field].type))
						.sort()
				].if(fields),
				...[
					...["Foreigns"].if(stringSeperators),
					...Object.keys(lib.schema[tableName])
						.filter((field) => !lib.schema[tableName][field].reverse)
						.filter((field) => ["foreign", "link", "externalLink"].includes(lib.schema[tableName][field].type))
						.sort()
				].if(foreigns),
				...[
					...["Reverses"].if(stringSeperators),
					...Object.keys(lib.schema[tableName])
						.filter((field) => lib.schema[tableName][field].reverse)
						.sort()
				].if(reverses)
			].mapToObject((field) => [field, lib.schema[tableName][field] || field])
		);
	}

	if (opts.order_by === "groups") {
		const pregroups = new Set(["Defaults"]);

		for (const field in lib.schema[tableName]) {
			pregroups.add(lib.schema[tableName][field].group_name);
		}

		if (pregroups.has("")) {
			pregroups.delete("");
			pregroups.add("");
		}

		const groups = {};

		pregroups.forEach((g) => {
			groups[g] = [];
			for (const [field, fieldDef] of Object.entries(lib.schema[tableName])) {
				if (fieldDef.group_name !== g) continue;
				groups[g].push(field);
			}
		});

		// @ts-ignore
		return Object.entries(
			[
				// ...["id", "created_at", "created_by", "updated_at", "updated_by"].if(
				// 	defaults
				// ),
				...Object.entries(groups)
					.map(([gName, fields]) => [...[gName].if(stringSeperators), ...(fields as Array<any>)])
					.flat()
			].mapToObject((field) => [field, lib.schema[tableName][field] || field])
		);
	}

	throw "no order by";
}

export function tryOr<T = unknown, F = unknown>(callback: () => T, fail?: F): T | F {
	try {
		const res = callback();
		return typeof res === "number" ? res : res ? res : fail;
	} catch (e) {
		// console.warn("tryOr failed");
		// console.warn(e);
		return fail;
	}
}

export function tryManyOr<T = unknown>(conditions: (() => T)[], fail: T): T {
	try {
		for (const _index in conditions) {
			const index = +_index;
			try {
				const res = conditions[index]();
				if (res) {
					return res;
				} else if (index !== conditions.length) {
					continue;
				} else return fail;
			} catch (e) {
				if (index !== conditions.length) {
					continue;
				} else throw e;
			}
		}
	} catch (error) {
		return fail;
	}
}

export function noop() {}

export function selfop(args) {
	return args;
}

export { default as clone } from "lodash/clone";

export function mod<T, N = unknown>(obj: T, callback: (obj: T) => T & N): T & N {
	return callback(obj);
}

export function random() {
	return Math.random().toString().replace("0.", "");
}

export const xf = {
	noop() {},
	selfop(args) {
		return args;
	},
	clone<T>(obj: T): T {
		return JSON.parse(JSON.stringify(obj));
	},
	mod<T, N = unknown>(obj: T, callback: (obj: T) => T & N): T & N {
		return callback(obj);
	},
	random() {
		return Math.random().toString().replace("0.", "");
	},
	Object<T>(obj: T) {
		return {
			burst: function (...keys: [keyof T] | (keyof T)[]) {
				const out: Partial<T> = {};
				if (Array.isArray(keys[0])) {
					keys = keys[0];
				}
				for (const key in obj) {
					if (!keys.includes(key)) {
						out[key] = obj[key];
					}
				}
				return out;
			},
			extract: function <S extends keyof T>(...keys: [S][] | S[]): { [Property in S]: T[S] } {
				const out: Partial<T> = {};
				for (const key in obj) {
					// @ts-ignore
					if (keys.includes(key)) {
						out[key] = obj[key];
					}
				}
				// @ts-ignore
				return out;
			},
			toArray<N extends keyof T>(keyOfKeyName: N): (T & { [Property in keyof N]: T[N] })[] {
				const out = [];
				for (const key in obj) {
					if (keyOfKeyName) {
						out.push({
							...obj[key],
							[keyOfKeyName]: key
						});
					} else out.push(obj[key]);
				}
				return out;
			},
			match(comparer: T): boolean {
				// match keys first
				if (JSON.stringify(Object.keys(obj)) !== JSON.stringify(Object.keys(obj))) {
					return false;
				}
				// then values
				for (const key in obj) {
					if (obj[key] != comparer[key]) {
						return false;
					}
				}
				return true;
			}
		};
	},
	dynamicSwitch<T, N>(prop: keyof T, obj: { [key in keyof T | "default"]: N }): N {
		let toMatch: keyof T | "default";
		if (prop in obj) {
			toMatch = prop;
		} else {
			toMatch = "default";
		}
		if (typeof obj[prop] === "function") {
			return this && this.isAsync
				? // @ts-ignore
				  Promise.resolve(obj[toMatch]())
				: // @ts-ignore
				  obj[toMatch]();
		} else {
			// @ts-ignore
			return obj[toMatch];
		}
	},
	Window: {
		getParam(name, url = window.location.href) {
			name = name.replace(/[\[\]]/g, "\\$&");
			var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return "";
			return decodeURIComponent(results[2].replace(/\+/g, " "));
		}
	},
	iife<T>(callback: () => void, returnValue?: T): T {
		callback();
		return returnValue;
	},
	iifeFalse(callback: () => void): false {
		callback();
		return false;
	},
	iifeEmpty(callback: () => void): "" {
		callback();
		return "";
	},
	iifeCustom<T>(callback: () => T): T {
		return callback();
	},
	Array: {
		toggle(arr: any[], value) {
			if (!arr.includes(value)) {
				arr.push(value);
			} else {
				arr.splice(
					arr.findIndex((v) => v == value),
					1
				);
			}
		}
	}
};

export function moveButtonFactory(
	index: number,
	arr: any[],
	onSwap: () => void = lib.xf.noop,
	remove = false
): { icon: string; action: () => void }[] {
	return [
		...[
			{
				icon: "remove",
				action() {
					arr.removeAt(index);
					onSwap();
				}
			}
		].if(remove),
		...[
			{
				icon: "south",
				action() {
					arr.swap(index, index + 1);
					onSwap();
				}
			}
		].if(index != arr.length - 1),
		...[
			{
				icon: "north",
				action() {
					arr.swap(index, index - 1);
					onSwap();
				}
			}
		].if(index != 0)
	];
}

export function copyAndPasteButtonFactory({ item, paste }) {
	return [
		{
			icon: "content_copy",
			action() {
				// @ts-ignore
				navigator.clipboard.writeText(JSON.stringify(item));
			}
		},
		{
			icon: "content_paste",
			action() {
				// @ts-ignore
				navigator.clipboard.readText().then((text) => {
					paste(JSON.parse(text));
				});
			}
		}
	];
}

export function flatObject(obj: any, joiner = ".") {
	const out = {};

	lib.walk(obj, {
		ignoreType: [],
		ignoreTypeOf: [],
		entry({ obj, path }) {
			if ((["bigint", "boolean", "number", "string"] as typeOfTypes[]).includes(typeof obj)) {
				out[path.join(joiner)] = obj;
			}
		}
	});

	return out;
}

export const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"] as day[];
export const fullDaysMap = {
	sun: "sunday",
	mon: "monday",
	tue: "tuesday",
	wed: "wednesday",
	thu: "thursday",
	fri: "friday",
	sat: "saturday"
};

export function getObjectStructure(obj: any): object | string {
	return getObjectStructureWith(obj, (v) => typeof v);
}

export function getObjectStructureWith(obj, fn: (v: string) => string) {
	if (lib.isObject(obj)) {
		return Object.entries(obj).reduce((p, [key, value]) => {
			p[key] = getObjectStructure(value);
			return p;
		}, {});
	} else {
		return fn(obj);
	}
}

export function getObjectStructureWithDatetime(obj: any): object | string {
	return getObjectStructureWith(obj, (v) =>
		typeof v === "string" && !isNaN(new Date(v).valueOf()) ? "datetime" : typeof v
	);
}

export function getFieldDefinition(path: string | string[]): {
	tableName: TschemaTableName;
	field: string;
	fieldDef: TschemaFieldDefinition | void;
} {
	try {
		if (typeof path === "string" && path.startsWith("$")) {
			path = jsonpath
				.parse(path)
				.slice(1)
				.map((part) => part.expression.value)
				.join(".");
		}
		if (typeof path === "string") {
			path = path.split(".");
		}

		path = path.filter((part) => isNaN(+part));

		do {
			const field = path[path.length - 1];
			const tableName = path[path.length - 2];

			if (lib.tryOr(() => !!lib.schema[tableName][field])) {
				return {
					// @ts-ignore
					tableName,
					field,
					fieldDef: lib.schema[tableName][field]
				};
			}
		} while (path.pop());
	} catch (error) {
		console.error(error);
	}
}

export function getFieldDefinitionForward(path: string | (string | number)[]): {
	tableName: TschemaTableName;
	field: string;
	fieldDef: TschemaFieldDefinition | void;
} | void {
	try {
		// 1. normalize path
		if (typeof path === "string" && path.startsWith("$")) {
			path = jsonpath
				.parse(path)
				.slice(1)
				.map((part) => part.expression.value)
				.join(".");
		}
		if (typeof path === "string") {
			path = path.split(".");
		}

		const new_path = path.filter((part) => isNaN(+part)).map(_.selfop);

		let last_known;

		const check = (tableName, field) => {
			if (String(tableName) in lib.schema) {
				if (String(field) in lib.schema[tableName]) {
					last_known = {
						tableName,
						field,
						fieldDef: lib.schema[tableName][field]
					};

					new_path.splice(0, 1);

					check(
						lib.tryOr(() => lib.schema[tableName][field][lib.schema[tableName][field].type].table),
						new_path[1]
					);
				}
			}
		};

		check(new_path[0], new_path[1]);
		return last_known;
	} catch (error) {
		console.error(error);
	}
}

export function undefinedForEmptyArray<T extends any[]>(arr: T): T | undefined {
	if (Array.isNotEmpty(arr)) {
		return arr;
	}
}

export function normalizeObjectWithIndexedArrays(obj) {
	lib.walk(obj, {
		ignoreType: ["Array"],
		ignoreTypeOf: ["string", "number", "undefined", "bigint", "boolean", "function", "symbol"],
		entry({ obj, set }) {
			if (Array.isIndexedObject(obj)) {
				set(Array.fromIndexedObject(obj));
			}
		}
	});
}

export const CONVERT_REGEX = /\$\:[A-Za-z0-9|\.|_]*/g;

export const get_evaluatable_context = (opts: { data: any; use_APP_CONFIG?: boolean; pb_element_id?: string }) => {
	const init = {
		data: opts.data,
		...(opts?.data?.store_value
			? {
					ds: opts.data.store_value,
					el: {
						...opts.data.store_value,
						self:
							typeof opts.pb_element_id === "string" && opts.pb_element_id in opts.data.store_value
								? opts.data.store_value[opts.pb_element_id]
								: undefined
					}
			  }
			: {}),
		...opts.data
	};
	return [
		["plugin", lib.PLUGIN_ENDPOINTS],
		["_", _],
		["lib", lib],
		["window", window],
		...(opts?.use_APP_CONFIG === false ? [] : [["APP_CONFIG", lib.getStoreValue(lib.APP_CONFIG)]])
	].reduce((p, [k, v]) => (k ? { ...p, [k]: v } : p), init);
};

/**
 * Convert a simple stringed template with a data object
 * @param {string} template
 * @param {stringOfAny} data Data keyed by replacers in template
 * @returns {string}
 * @example
 *
 * const template = "$:Employee.0.firstname, $:Employee.0.lastname";
 *
 * const data = {
 * 	Employee: [
 * 		{
 * 			id: 123,
 * 			firstname: 'foo',
 * 			lastname: 'bar'
 * 		}
 * 	]
 * };
 *
 * const out = converTemplate(template, data);
 *
 * console.log(out);
 * // outputs:
 * // foo, bar
 */
export function convertTemplate(
	template: string,
	data: stringOfAny,
	opts: { use_APP_CONFIG?: boolean; throw_errors?: boolean; pb_element_page_uid?: string; pb_element_id?: string } = {}
): string {
	if (typeof template !== "string" || !(template.match(CONVERT_REGEX) || template.match("{{"))) {
		return template;
	}
	// console.log(opts)
	let svelte_ctx;
	try {
		svelte_ctx = svelte.getAllContexts();
		const ctx = svelte_ctx;
		opts.pb_element_id = ctx.prepend_uid ? ctx.instance_uid.replace(ctx.prepend_uid, "") : ctx.instance_uid;
	} catch {
		// no ctx
	}
	try {
		const pre_out =
			(lib.matchType(template, "string", "", true) || "")
				.replace(CONVERT_REGEX, (substr) => {
					false && console.log("OLD METHOD OF TEMPLATING USED", "extracted:", substr);
					substr = substr.substring(2);
					// console.log(opts);
					// debugger;
					return (
						tryOr(
							() =>
								lib.accessFromString(
									get_evaluatable_context({
										data,
										use_APP_CONFIG: opts?.use_APP_CONFIG
									}),
									substr
								),
							""
						) || ""
					);
				})
				.replace(/(null(\.([^ ])*)*)/g, "") || "";

		const to_eval = get_evaluatable_context({ data, use_APP_CONFIG: opts?.use_APP_CONFIG });
		if (!!to_eval?.el && opts?.pb_element_id && !!to_eval?.el && !to_eval?.el?.self) {
			to_eval.el.self = to_eval.el[opts.pb_element_id];
		}

		return _.template(pre_out)(to_eval);
	} catch (e) {
		if (opts.throw_errors) {
			throw e;
		}
		if (!(e instanceof ReferenceError)) {
			console.error(e);
		}
		return "";
	}
}

export async function convertTemplateAsync(
	template: string,
	data: stringOfAny,
	opts: { use_APP_CONFIG?: boolean; throw_errors?: boolean; pb_element_page_uid?: string; pb_element_id?: string } = {}
): Promise<string> {
	if (typeof template !== "string" || !(template.match(CONVERT_REGEX) || template.match("{{"))) {
		return template;
	}
	// console.log(opts)
	let svelte_ctx;
	try {
		svelte_ctx = svelte.getAllContexts();
		const ctx = svelte_ctx;
		opts.pb_element_id = ctx.prepend_uid ? ctx.instance_uid.replace(ctx.prepend_uid, "") : ctx.instance_uid;
	} catch {
		// no ctx
	}
	// console.log(opts)
	try {
		const pre_out =
			(lib.matchType(template, "string", "", true) || "")
				.replace(CONVERT_REGEX, (substr) => {
					false && console.log("OLD METHOD OF TEMPLATING USED", "extracted:", substr);
					substr = substr.substring(2);
					// console.log(opts);
					// debugger;
					return (
						tryOr(
							() =>
								lib.accessFromString(
									get_evaluatable_context({
										data,
										use_APP_CONFIG: opts?.use_APP_CONFIG
									}),
									substr
								),
							""
						) || ""
					);
				})
				.replace(/(null(\.([^ ])*)*)/g, "") || "";
		// console.log(pre_out, get_evaluatable_context({ data, use_APP_CONFIG: opts?.use_APP_CONFIG }))
		const to_eval = get_evaluatable_context({ data, use_APP_CONFIG: opts?.use_APP_CONFIG });
		if (!!to_eval?.el && opts?.pb_element_id && !!to_eval?.el && !to_eval?.el?.self) {
			to_eval.el.self = to_eval.el[opts.pb_element_id];
		}
		return await _.templateAsync(pre_out)(to_eval);
	} catch (e) {
		if (!(e instanceof ReferenceError)) {
			console.error(e);
		}
		return "";
	}
}

export const COMMON_ERRORS = {
	REQUIRED_NOT_MET: Symbol("REQUIRED_NOT_MET"),
	EMPTY_CONVERTY_TEMPLATE_VALUE: Symbol("EMPTY_CONVERTY_TEMPLATE_VALUE")
};

export class CheckableQueu {
	constructor(public check: () => PPromise<boolean>) {}
	private queu = [];
	private async next() {
		if (await this.check()) {
			const fn = this.queu.shift();
			if (typeof fn === "function") {
				await fn();
				this.next();
			}
		}
	}
	public add(fn: () => any) {
		this.queu.push(fn);
		this.next();
	}
	public trigger() {
		this.next();
	}
}

export function flat_app_routes(routes = getStoreValue(ROUTES)): {
	name: string;
	path: string;
} & ({ uid: string; builtin?: never } | { builtin: string; uid?: never })[] {
	const out = [];

	lib.walk(routes, {
		entry({ obj, type, history }) {
			if (type === "Object") {
				if (!!obj.path && (!!obj.uid || !!obj.builtin)) {
					out.push({
						// name: [
						// 	...history
						// 		.filter((hisItem) => Array.isNotArray(hisItem) && !!hisItem.name)
						// 		.mapFromObjectKey("name"),
						// 	obj.name
						// ].join(" "),
						name: obj.name,
						path: [
							...history
								.filter((hisItem) => Array.isNotArray(hisItem) && !!hisItem.path && hisItem.path !== "/")
								.mapFromObjectKey("path"),
							obj.path
						].join(""),
						..._.clone(_.omit(obj, "items", "path", "name"))
					});
				}
			}
		}
	});

	return out as ReturnType<typeof flat_app_routes>;
}

const cachers = {};
export function factoryCacher<T>(name: string, make: (prop: string) => T): (prop: string) => T {
	if (cachers[name]) {
		return cachers[name];
	}
	const cache_target = {};

	function create(prop) {
		cache_target[prop] = make(prop);
	}

	// const out = new Proxy(cache_target, {
	// 	get(target, prop) {
	// 		if (!target[prop]) {
	// 			create(prop);
	// 		}
	// 		return target[prop];
	// 	},
	// 	set() {
	// 		return false;
	// 	}
	// });

	const out = (prop) => {
		if (!cache_target[prop]) {
			create(prop);
		}
		return cache_target[prop];
	};

	cachers[name] = out;

	return out;
}

const asyncCachers: stringOf<AsyncCacher<any>> = {};
class AsyncCacher<T> {
	public static construct<T>(name: string, make: (prop: string) => PPromise<T>): AsyncCacher<T> {
		if (asyncCachers[name]) {
			return asyncCachers[name];
		} else {
			const cacher = new AsyncCacher<T>(make);
			asyncCachers[name] = cacher;
			return cacher;
		}
	}

	private store: stringOf<T> = {};

	async get(prop: string) {
		if (!this.store[prop]) {
			this.store[prop] = await this.make(prop);
		}
		return this.store[prop];
	}

	constructor(public make: (prop: string) => PPromise<T>) {}
}

export const async_cacher = AsyncCacher.construct;

export class Queueable<T> extends Function {
	private queue: Function[] = [];
	private processing: boolean = false;

	constructor(public fn) {
		super("return arguments.callee._call.apply(arguments.callee, arguments)");
	}

	private _call(...args) {
		this.queue.push(() => this.fn.call(null, ...args));
		this.run();
	}

	private async run() {
		if (!this.processing && this.queue.length > 0) {
			this.processing = true;

			const process = this.queue.shift();

			if (typeof process === "function") {
				const out = process();
				if (out instanceof Promise) {
					await out;
				}
			}

			this.processing = false;
			this.run();
		}
	}

	static create(fn) {
		return new Queueable(fn);
	}
}

/**
 * @author https://stackoverflow.com/a/41491220
 */
export function textColorByBg(bgColor: string, lightColor = "#FFF", darkColor = "#000") {
	var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
	var r = parseInt(color.substring(0, 2), 16); // hexToR
	var g = parseInt(color.substring(2, 4), 16); // hexToG
	var b = parseInt(color.substring(4, 6), 16); // hexToB
	var uicolors = [r / 255, g / 255, b / 255];
	var c = uicolors.map((col) => {
		if (col <= 0.03928) {
			return col / 12.92;
		}
		return Math.pow((col + 0.055) / 1.055, 2.4);
	});
	var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
	return L > 0.179 ? darkColor : lightColor;
}

export function last_value_control<T>(
	getter: () => PPromise<T>,
	setter: (value: T) => void,
	checker: (old_v: T, new_v: T) => PPromise<boolean> = (a, b) => a == b
): () => void {
	let last_value;
	return async function trigger() {
		const new_last_value = await getter();

		if (await checker(last_value, new_last_value)) {
			last_value = new_last_value;
			setter(new_last_value);
		}
	};
}

export function modStore<T>(store: Writable<T>, fn: (value: T) => void = _.noop) {
	store.update((state) => {
		fn(state);
		return state;
	});
}

export async function modStoreAsync<T>(store: Writable<T>, fn: (value: T) => void) {
	const value = lib.getStoreValue(store);
	await fn(value);
	store.set(value);
	return;
}

export function iterable<T>(cb: () => { done: boolean; value?: T }): Iterable<T> {
	return {
		// @ts-ignore
		next() {
			return {
				...cb(),
				next: this.next
			};
		},
		[Symbol.iterator]() {
			return this;
		}
	};
}

export function iterated<T>(cb: () => { done: boolean; value?: T }): T[] {
	return Array.from(lib.iterable(cb));
}

export function controlled_collection<T extends object = {}>() {
	type PT = T & { id: number };
	type CB = (obj: PT) => void;

	let id = 0x1;

	const holder: (T & {
		id: number;
	})[] = [];
	const { set, subscribe, update } = svelte.store.writable(holder);

	const single_subscribers = new Set<CB>();

	function add(obj: T) {
		const ref = obj as PT;
		if (isObject(obj)) {
			if (!ref.id) {
				ref.id = id;
				id++;
			}
			holder.push(ref);
			for (const cb of single_subscribers) {
				cb(ref);
			}
			set(holder);
		}
	}

	function subscribe_single(callback: CB) {
		single_subscribers.add(callback);
		return () => {
			single_subscribers.delete(callback);
		};
	}

	function reset() {
		holder.length = 0;
		set(holder);
	}

	function remove(id) {
		const foundIndex = holder.findIndex((item) => item.id === id);
		if (foundIndex > -1) {
			const out = holder.splice(foundIndex, 1);
			set(holder);
			return out;
		}
	}

	return {
		set,
		update,
		subscribe,
		get() {
			return lib.getStoreValue({ subscribe });
		},
		subscribe_single,
		add,
		reset,
		remove
	};
}

export async function getter<T, cb extends (t: T) => void>(args: {
	cb: cb;
	fn: (cb: cb) => PPromise<T>;
}): Promise<boolean> {
	let called = false;
	// @ts-ignore
	await args.fn((v) => {
		called = true;
		args.cb(v);
	});
	return called;
}

export function deep_set(obj: object, _path: string | string[], value) {
	const path = [...(typeof _path === "string" ? _path.split(".") : _path)];
	const key = path.pop();

	let current = obj;
	for (const p of path) {
		try {
			current = current[p];
		} catch (error) {
			// invalid obj, cancel
			return;
		}
	}

	current[key] = value;

	return;
}

export function deep_set_unsafe(obj, path, value) {
	_.merge(obj, lib.emptyObjectFromString(path, value));
}

export function djb2(str: string) {
	let hash = 5381;
	for (let i = 0; i < str.length; i++) {
		hash = (hash * 33) ^ str.charCodeAt(i);
	}
	return hash >>> 0;
}

export function debugNextTemp(value) {
	let counter = 1;
	while (Object.hasOwn(window, `temp${counter}`)) {
		counter++;
	}
	const label = `temp${counter}`;
	makeGlobal(label, value);
	console.log(label, value);
	return label;
}

export function buildJsonFromJSONSchema(def: JSONSchema7) {
	if (def.type === "object") {
		const out = {};
		for (const property in def.properties) {
			// @ts-ignore
			out[property] = buildJsonFromJSONSchema(def.properties[property]);
		}
		return out;
	} else if (def.type == "string") {
		return "string";
	} else if (def.type == "number") {
		return "number";
	} else if (def.type == "null") {
		return "null";
	} else if (def.type == "boolean") {
		return "boolean";
	} else if (def.type == "array") {
		return [buildJsonFromJSONSchema(_.first(Array.isArray(def.items) ? def.items : [def.items]))];
	} else {
		console.log({ def });
		return def.type || "any";
	}
}

export const jsondiffpatch = jdp.create({
	objectHash: function (obj, i) {
		return obj?.id || i;
	},
	arrays: {
		detectMove: true
	}
});

export { jdp };

makeGlobals({
	jsondiffpatch
});

/**
 * JSONPath + addons
 */

export type jp_part =
	| {
			expression: { type: "root"; value: "$" };
			operation: never;
	  }
	| {
			expression: { type: "identifier"; value: string };
			scope: "child";
			operation: "member";
	  }
	| {
			expression:
				| { type: "numeric_literal"; value: number }
				| { type: "string_literal"; value: number }
				| { type: "wildcard"; value: "*" };
			scope: "child";
			operation: "subscript";
	  };

window.jsonpath = jsonpath;

export const json_path_is_multi = (path: string) => !!path.match(/\*/);

export const json_path_extract = (obj: stringOfAny, path: string, opts = {}) => {
	try {
		const is_multi = json_path_is_multi(path);
		const res = jsonpath.query(obj, path);
		return is_multi ? res : res[0];
	} catch {}
};

export const json_path_create_last_properties_if_not_exists = (obj: any, path: string, initValue: any, opts = {}) => {
	const parts = jsonpath.parse(path);
	if (parts[0].expression.type === "root") {
		parts.shift();
	}

	const {
		expression: { value: final_prop }
	} = parts.pop();

	if (parts.length == 0) {
		if (!Object.hasOwn(obj, final_prop)) {
			obj[final_prop] = initValue;
		}
	} else {
		jsonpath.apply(obj, path, (parent) => {
			if (!Object.hasOwn(parent, final_prop)) {
				parent[final_prop] = initValue;
			}
			return parent;
		});
	}
};

export const _json_path_apply_with_creations = (obj: any, path: string, value: any) => {
	if (json_path_is_multi(path)) {
		console.error("jsonpath apply deep is not available for multi paths");
		return;
	}

	const parts: jp_part[] = jsonpath.parse(path);

	if (parts[0].expression.type === "root") {
		parts.shift();
	}

	const last_part = parts.pop();

	if (!last_part) {
		return;
	}

	let ref = obj;
	for (const part of parts) {
		if (part.operation !== "subscript") {
			console.error("should not exist");
			console.log(part);
			return;
		}

		if (part.expression.type === "string_literal") {
			// ie objects
			if (typeof ref[part.expression.value] !== "object" || ref[part.expression.value] === null) {
				console.log("need to create object");
				console.log(_.cloneDeep(part), _.cloneDeep(ref));
			}
			ref = ref[part.expression.value];
		} else if (part.expression.type === "numeric_literal") {
			// ie arrays
			if (!Array.isArray(ref)) {
				console.log("need to create array");
				console.log(_.cloneDeep(part), _.cloneDeep(ref));
			}
			ref = ref[part.expression.value];
		} else {
			console.error("no JSONPath implementation for path part");
			console.log(part);
		}
	}

	ref[last_part.expression.value] = value;
};

export const json_path_apply_with_creations = (obj: any, path: string, value: any | ((current: any) => any)) => {
	if (json_path_is_multi(path)) {
		console.error("jsonpath apply deep is not available for multi paths");
		return;
	}

	const parts: jp_part[] = jsonpath.parse(path);

	if (parts[0].expression.type === "root") {
		parts.shift();
	}

	const last_part = parts.pop();

	if (!last_part) {
		return;
	}

	let ref = obj;
	for (const partIndex in parts) {
		const part = parts[partIndex];
		const nextPart = parts[+partIndex + 1] || last_part;
		if (part.operation !== "subscript") {
			// console.groupCollapsed(
			// 	"bunch of logs because old style path part is used",
			// 	part.expression.value,
			// 	"in",
			// 	path
			// );
			// console.error("should not exist");
			// console.log(part);

			/**
			 * THIS RETURN SHOULD BE UNCOMMENTED IN THE FUTURE
			 *
			 * code here will only run in non "numeric_literal" or "string_literal" will be in the JSONP expression
			 * i.e. ["test"][0].name["length"]
			 *
			 * the part for `name` will be here, which we shouldn't do because it tampers with ability for proper type detection
			 */
			// return;

			if (!ref[part.expression.value]) {
				const should_be = { numeric_literal: "array", identifier: "object" }[nextPart.expression.type];
				if (!should_be) {
					console.log({ nextPart });
					console.error("dont know the next_should_be");
					return;
				}
				ref[part.expression.value] = should_be === "array" ? [] : {};
			}
			ref = ref[part.expression.value];

			// console.groupEnd();
		} else {
			if (!ref[part.expression.value]) {
				const should_be = { numeric_literal: "array", string_literal: "object" }[nextPart.expression.type];
				ref[part.expression.value] = should_be === "array" ? [] : {};
			}
			ref = ref[part.expression.value];
		}
	}

	ref[last_part.expression.value] = typeof value === "function" ? value(ref[last_part.expression.value]) : value;
};

export const json_path_get_path_parts = (path: string) => {
	const parts: jp_part[] = jsonpath.parse(path);

	if (parts[0].expression.type === "root") {
		parts.shift();
	}

	return parts.map((part) => part.expression.value);
};

/**
 *
 * NEEDS TESTING
 * Same FN as above, with support for multi paths
 *
 * @param obj
 * @param path {}
 * @param {any} value Value to set (may add callback in the future)
 * @returns {void} nothing
 */
export const __json_path_apply_with_creations = (obj: any, path: string, value: any) => {
	const parts: jp_part[] = jsonpath.parse(path);

	if (parts[0].expression.type === "root") {
		parts.shift();
	}

	const last_part = parts.pop();

	if (!last_part) {
		return;
	}

	const scan = (ref: any, part_index: number) => {
		const part = parts[part_index];
		const next_part = parts[part_index + 1] || last_part;

		if (!part) {
			// apply_value
			ref[last_part.expression.value] = value;
		} else {
			if (part.operation !== "subscript") {
				/**
				 * THIS RETURN SHOULD BE UNCOMMENTED IN THE FUTURE
				 *
				 * code here will only run in non "numeric_literal" or "string_literal" will be in the JSONP expression
				 * i.e. ["test"][0].name["length"]
				 *
				 * the part for `name` will be here, which we shouldn't do because it tampers with ability for proper type detection
				 */
				// return;
				if (!ref[part.expression.value]) {
					const should_be = { numeric_literal: "array", identifier: "object" }[next_part.expression.type];
					if (!should_be) {
						console.log({ next_part });
						console.error("dont know the next_should_be");
						return;
					}
					ref[part.expression.value] = should_be === "array" ? [] : {};
				}
				scan(ref[part.expression.value], part_index + 1);
			} else {
				if (part.expression.type === "wildcard") {
					for (const item of ref || []) {
						scan(item, part_index + 1);
					}
				} else {
					if (!ref[part.expression.value]) {
						const should_be = {
							numeric_literal: "array",
							string_literal: "object"
						}[next_part.expression.type];
						ref[part.expression.value] = should_be === "array" ? [] : {};
					}
					scan(ref[part.expression.value], part_index + 1);
				}
			}
		}
	};

	scan(obj, 0);
};

const transformations = {
	string: {
		number: (value) => parseFloat(value),
		int: (value) => parseInt(value),
		float: (value) => parseFloat(value),
		base64: (value) => Buffer.from(value, "base64").toString("utf-8")
	},
	number: {
		string: (value) => value.toString(),
		base64: (value) => Buffer.from(value.toString()).toString("base64")
	},
	object: {
		array: (value) => Object.values(value)
	},
	array: {
		object: (value) => JSON.parse(value)
	}
	// Add more transformations here
};

export const apply_transform_conversion = ({ value, to }, cs = console) => {
	const pre_type =
		value === null ? undefined : typeof value === "object" ? (Array.isArray(value) ? "array" : "object") : typeof value;

	if ((pre_type || "") in transformations) {
		if (transformations[pre_type][to]) {
			const final_value = _.attempt(() => transformations[pre_type][to](value));

			if (_.isError(final_value) || (typeof final_value !== "object" && isNaN(final_value))) {
				cs.error(
					"Tried transform.convert_to with",
					{
						soorce_type: pre_type,
						target_type: to
					},
					"but resulted in",
					final_value
				);
			} else {
				value = final_value;
				return value;
			}
		} else {
			cs.error("Cannot transform.convert_to", "because source type", pre_type, "does not does not have", to);
		}
	} else {
		cs.error("Cannot transform.convert_to", to, "because value is type", pre_type);
	}
};

export const condense_arrays_in_structured_object = (obj) => {
	const condensable = (b) => (Array.isArray(b) || Array.isIndexedObject(b)) && _.keys(b).length > 0;

	if (!lib.isObject(obj) || obj === null) {
		return obj;
	}

	const make_new_structure = (b) => _.values(b).reduce((a, c) => _.mergeWith(a, c, merge_fn), {});

	const merge_fn = (a, b) => {
		// a is gonna be most likely blank at this point, so just return b
		// unless it is an object
		if (typeof b !== "object" || b === null) {
			return b;
		}
		// console.log(b)
		// if it is a array, we return two things: { 0: b, *: b }
		if (condensable(b)) {
			// merge all possibilities
			const new_structure = make_new_structure(b);
			return { 0: new_structure, "*": new_structure };
		}
		// console.log(a, b);
		return _.mergeWith(a, b, merge_fn);
	};

	const out = _.mergeWith({}, obj, merge_fn);
	return out;
};

export const button_helpers = {
	help: (help: { short?: string; long?: string; icon?: string } = {}) =>
		!help.short
			? undefined
			: ({
					icon: help.icon || "help",
					color: "light",
					icon_abbr: help.short,
					action() {
						!!help.long &&
							lib.dialog({
								component: Html,
								props: {
									html: help.long,
									title: help.short
								}
							});
					}
			  } as CommonButton),
	danger: (msg?: string, action?: () => void) =>
		!msg
			? undefined
			: {
					icon: "error",
					color: "danger",
					icon_abbr: msg + (action ? " ... Press here to automatically resolve the issue" : ""),
					action
			  }
};

export const getDefaultFormatter = (tableName: TschemaTableName | string) =>
	tableName in lib.schema
		? lib.getStoreValue(REAL_APP_CONFIG).default_formatters[tableName] ||
		  ("name" in lib.schema[tableName] ? "$:name" : "[$:id]")
		: "Invalid table name passed to formatted";
